import { makeAutoObservable } from "mobx"
import uniq from "lodash/uniq"

import productsService from "@services/products.service"
import { CompanyTheme } from "@framework/types/company"
import { FailResponse, SuccessResponse } from "@framework/types/common"
import { CellValidationRule } from "@components/ui/Spreadsheet/types"
import {
  createMatrix,
  createMatrixFromSnapshot,
} from "@components/ui/Spreadsheet/createMatrix"

import UnifiedMatrixStore from "./unified-matrix.store"

export default class UnifiedMatrixController {
  matrix: UnifiedMatrixStore

  constructor(injections: { unifiedMatrixStore: UnifiedMatrixStore }) {
    this.matrix = injections.unifiedMatrixStore

    makeAutoObservable(this)
  }

  initNewInstance = async (solutionID: string, initNew = true) => {
    const store = this.matrix

    try {
      store.isLoading = true
      store.error = null

      const validationRules: Record<string, CellValidationRule> = {
        DROPDOWN_FILE: {
          type: "AUTOCOMPLETE",
          search: this.fetchFiles,
        },
      }

      const localSnapshot = store.snapshotStore.getSnapshot(solutionID)

      const newInstance =
        initNew || localSnapshot == null
          ? createMatrix({
              initialValue: [
                ["Company", "Category", "Business Unit", "Generated Themes"],
              ],
              validationRules,
            })
          : createMatrixFromSnapshot({
              snapshot: localSnapshot,
              validationRules,
            })

      store.instances.set(solutionID, newInstance)

      return newInstance
    } catch (error) {
      store.error = "Initialization failed"
    }

    return null
  }

  filesAbortController: AbortController | null = null

  fetchFiles = async (query: string): Promise<string[]> => {
    const store = this.matrix.filesStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await productsService.getTargetFiles(
        query,
        this.filesAbortController.signal
      )

      store.setData(response.data)

      return uniq(response.data.data.map((it) => it.name))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  generateThemes = async (
    companyName: string,
    category: string,
    businessUnit?: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateThemes({
        companyName,
        category,
        businessUnit,
      })

      const themes = response.data.data

      return {
        status: "SUCCESS",
        data: themes.length ? makeThemesReport(themes) : "-",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }

  generateDocumentComplianceReport = async (
    parentDocument: string,
    childDocument: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateDocumentComplianceReport({
        parentDocument,
        childDocument,
      })

      return {
        status: "SUCCESS",
        data: response.data,
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }
}

const makeThemesReport = (themes: CompanyTheme[]) => {
  return themes.map((it) => `- ${it.content}`).join("\n")
}
