import React from "react"
import { useAlert } from "react-alert"

import { copyToClipboard } from "@utils/clipboard"
import Box from "@components/ui/Dropdown/Box"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"

import styles from "./CellLinkTooltip.module.sass"

export const CellLinkTooltip: React.FC<{ url: string }> = ({ url }) => {
  const alert = useAlert()

  return (
    <Box color="primary">
      <List direction="row" gutter="8" align="center">
        <Icon name="external-link" />
        <a
          href={url}
          title={url}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          {url}
        </a>

        <IconButton
          title="Copy to clipboard"
          onClick={(e) => {
            copyToClipboard(url)
            alert.info("Link copied to clipboard")
            e.stopPropagation()
          }}
        >
          <Icon name="clipboard" />
        </IconButton>
      </List>
    </Box>
  )
}

export default CellLinkTooltip
