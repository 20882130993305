import React from "react"
import { observer } from "mobx-react-lite"

import { useMatrixContext } from "./MatrixContext"
import { rangeToCode } from "./utils"
import HighlightedText from "./parser/FormulaPreview"
import Text from "../Typography/Text"
import RichTextarea from "./TextEditorCell/RichTextarea"

import styles from "./CellControlHeader.module.sass"

interface CellControlHeaderProps {}

export const CellControlHeader: React.FC<CellControlHeaderProps> = observer(
  () => {
    const { selectedRange, editManager } = useMatrixContext()

    const control = editManager.activeCellState

    const cell = editManager.getCellAtPoint(selectedRange.origin)

    const inputProps = control
      ? {
          value: control.input,

          onChange: (e: any) => {
            const text = e.target.value
            control.setInput(text)
          },
        }
      : {
          value: cell.state.input,
          onFocus: () =>
            editManager.editCell(selectedRange.origin, { focusCell: false }),
        }

    const formula = control ? control.formula : cell.state.formula

    return (
      <div className={styles.root}>
        <Text className={styles.address} variant="caption1">
          {rangeToCode(selectedRange.range)}
        </Text>

        <div className={styles.input}>
          <RichTextarea
            className={styles.textarea}
            {...inputProps}
            style={{ width: "100%" }}
          >
            <HighlightedText value={inputProps.value} tree={formula} />
          </RichTextarea>
        </div>
      </div>
    )
  }
)

export default CellControlHeader
