import React from "react"

import Box from "@components/ui/Dropdown/Box"
import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"

import styles from "./ErrorTooltip.module.sass"

export const ErrorTooltip: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box color="primary">
      <List direction="column" gutter="4">
        <Text color="redColor" variant="h5">
          Error
        </Text>
        <Text variant="body3" className={styles.message}>
          {children}
        </Text>
      </List>
    </Box>
  )
}

export default ErrorTooltip
