import { useFormikContext } from "formik"
import React from "react"

import Button from "@components/ui/Button/Button"
import VoiceInputButton from "@components/ui/Button/VoiceInputButton"
import { useStore } from "@store/index"
import useSpeechRecognizer from "@pages/search/components/SearchWidget/useSpeechRecognizer"
import SearchInputField from "@pages/search/components/SearchWidget/SearchInputField"
import Icon from "@components/ui/Icon/Icon"

import styles from "./SearchInput.module.sass"

interface Props {
  disabled?: boolean
}

export const SearchInput: React.FC<Props> = ({ disabled }) => {
  const { restrictionsStore: access } = useStore()

  const formik = useFormikContext<any>()

  const speechControl = useSpeechRecognizer({
    onChange: (value) => {
      formik.setFieldValue("query", value)
    },
  })

  const isActive = speechControl.listening
  const canSearch = !disabled && !isActive && formik.values.query.length > 0

  return (
    <>
      <SearchInputField
        disabled={isActive || disabled}
        placeholder=" Ask a question"
        name="query"
      />

      {access.isSearchVoiceRecognitionEnabled && (
        <VoiceInputButton
          size="big"
          color="default"
          listening={isActive}
          className={styles.voiceInputBtn}
          onClick={() => speechControl.toggle()}
        />
      )}

      <Button
        size="big"
        color="primary"
        type="submit"
        autoFocus={isActive}
        disabled={!canSearch}
        className={styles.searchButton}
        onClick={() => speechControl.toggle(false)}
      >
        <Icon name="arrow-up" />
      </Button>
    </>
  )
}

export default SearchInput
