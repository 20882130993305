import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"

import SelectionRange from "./SelectionRange"
import { useMatrixContext } from "./MatrixContext"
import EditorCell from "./EditorCell"
import SelectionSquare from "./SelectionSquare"

export const SelectionLayer: React.FC = observer(({ children }) => {
  const state = useMatrixContext()

  const isSelectedEditing = state.editManager.isCellEditing(
    state.selectedRange.origin
  )

  if (state.grid.gridElement == null) return null
  return ReactDOM.createPortal(
    <>
      <SelectionSquare cell={state.selectedRange.origin} />

      {state.spreadRange != null && (
        <SelectionRange range={state.spreadRange.range} />
      )}

      <SelectionRange
        range={state.selectedRange.range}
        blurred={state.selectedRange.isRange}
        withKnob={
          !state.selectedRange?.rangeStarted &&
          !state.spreadRange?.rangeStarted &&
          !isSelectedEditing
        }
        solid
        onKnobMouseDown={(e) => {
          state.startSpreading()
          e.preventDefault()
          e.stopPropagation()
        }}
      />

      {state.editManager.activeCellState?.validRefEntries.map(
        ([name, range]) => (
          <SelectionRange
            range={range}
            solid={false}
            key={name}
            blurred
            color="rgb(255 190 78)"
            bgColor="rgb(255 190 78 / 0.2)"
          />
        )
      )}

      {isSelectedEditing && (
        <EditorCell
          cell={state.selectedRange.origin}
          key={state.editManager.activeCellId}
        />
      )}

      {children}
    </>,
    state.grid.gridElement
  )
})

export default SelectionLayer
