import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController } from "@store/index"
import Text from "@components/ui/Typography/Text"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import BackButton from "@components/prototypes/BackButton"
import Chip from "@components/ui/Chip/Chip"
import { AnswerItem } from "@framework/types/search"
import NotFound from "@components/ui/NotFound/NotFound"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

import ImageData from "./components/ImageResult/ImageResult"
import { ImageDetails } from "./components/ImageResult/AnswerOriginalView/AnswerOriginalView"

import styles from "./SourceView.module.sass"

export interface SourceViewProps {}

export const SourceView: React.FC<SourceViewProps> = observer(() => {
  const { searchEntityBlock } = useSearchBlock(SearchSummaryBlockStore)
  const { allAnswers } = searchEntityBlock.searchPassages || {}

  const { dataConnectorController } = useController()

  const [citation, setCitation] = useState({
    answer: allAnswers?.[0].value,
    number: 1,
  })

  const handleSourceClick = async () => {
    const sourceUrl = answer?.source
    if (!sourceUrl) return
    const res = await dataConnectorController.getSignedDataURL(sourceUrl)
    if (res.status === "SUCCESS") window.open(res.data, "_blank")
  }
  const { answer } = citation

  const imageUrls: ImageDetails[] =
    allAnswers?.flatMap((item, index) => ({
      url: Array.isArray(item.value.screenshots)
        ? item.value.screenshots.join(", ")
        : item.value.screenshots ?? "",
      title: `Citation`,
      id: item.name,
      citation: index + 1,
      description: (item.value as any).text ?? (item.value as any).value,
      isExpertAnswer: item.value?.source === "expert answer",
      details: item.value as unknown as AnswerItem,
    })) || []

  const isExpertKnowledge = answer?.source === "expert answer"

  const handleImageChange = (imageDetails: ImageDetails) => {
    const newAnswer = allAnswers?.find((item) => item.name === imageDetails?.id)
    if (newAnswer)
      setCitation({
        answer: newAnswer.value,
        number: imageDetails.citation,
      })
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <BackButton size="small">Back</BackButton>
      </div>
      <div className={styles.card}>
        {allAnswers?.length ? (
          <>
            <div className={styles.citationHeader}>
              <Text variant="h4">
                Citation
                <Chip color="primary-solid" className={styles.citationNumber}>
                  {citation.number}
                </Chip>
              </Text>
            </div>
            {!isExpertKnowledge && (
              <Chip color="green-solid" variant="rounded">
                New
              </Chip>
            )}
            {answer?.source_name && (
              <div className={styles.headingContainer}>
                <div className={styles.heading}>
                  <Icon
                    color={isExpertKnowledge ? "green" : "gold"}
                    name={isExpertKnowledge ? "user-insight" : "global"}
                  />
                  <Tooltip
                    content={
                      <TooltipContainer placement="top">
                        {answer?.source_name}
                      </TooltipContainer>
                    }
                  >
                    <Text variant="h4" className={styles.title}>
                      {isExpertKnowledge
                        ? "Expert Insights"
                        : answer?.source_name}
                    </Text>
                  </Tooltip>

                  {!!answer?.source && !isExpertKnowledge && (
                    <Button
                      onClick={handleSourceClick}
                      className={clsx(styles.source)}
                      color="secondary"
                      size="small"
                      before={<Icon name="external-link" />}
                    >
                      Open file
                    </Button>
                  )}
                </div>
                <div className={styles.voteButtons}>
                  <Button
                    color="secondary"
                    size="small"
                    before={<Icon name="big-arrow-up" />}
                  >
                    Upvote
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    before={<Icon name="big-arrow-up" rotateAngle={180} />}
                  >
                    Downvote
                  </Button>
                </div>
              </div>
            )}

            <ImageData imageUrls={imageUrls} onSelect={handleImageChange} />
          </>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  )
})

export default SourceView
