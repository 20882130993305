import React from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import Loader from "@components/ui/Loader/BarLoader"
import {
  camelCaseToWords,
  convertFirstLetterToUpper,
  removeHyphens,
} from "@utils/textUtils"
import { CategoryInfo, QueryFilterData } from "@framework/types/query-filter"

import styles from "./ProductsSection.module.sass"

interface CategoryListProps {
  selectedCategory: CategoryInfo
  items: QueryFilterData[]
  handleCategoryBack: () => void
  checkAndApplyFilter: (filter: QueryFilterData) => void
  subFilterSelect: (filter: QueryFilterData, parent: string) => void
  filtersToApply: QueryFilterData[]
  isPaginationLoading: boolean
  lastItemRef: React.RefObject<HTMLDivElement>
  subFilterName?: string
}

const CategoryList: React.FC<CategoryListProps> = ({
  selectedCategory,
  items,
  handleCategoryBack,
  checkAndApplyFilter,
  subFilterSelect,
  filtersToApply,
  isPaginationLoading,
  lastItemRef,
  subFilterName,
}) => (
  <>
    <Text variant="body2" className={styles.filtersHeader}>
      <Button
        onClick={handleCategoryBack}
        variant="text"
        size="tiny"
        before={<Icon name="line-arrow-down" rotateAngle={90} />}
      >
        {convertFirstLetterToUpper(
          camelCaseToWords(
            subFilterName ||
              selectedCategory?.name?.replace(/^data/, "content") ||
              ""
          )
        )}
      </Button>
    </Text>
    <div className={styles.scrollable}>
      <div className={clsx(styles.categories, styles.loaderList)}>
        {items.map((filter, index) => (
          <div
            className={styles.checkboxWrapper}
            ref={index === items.length - 1 ? lastItemRef : null}
            key={filter.id || filter.name}
          >
            <CheckboxWithLabel
              key={filter.id}
              className={clsx(styles.checkboxFilterName)}
              onCheck={() => checkAndApplyFilter(filter)}
              label={convertFirstLetterToUpper(removeHyphens(filter.name))}
              checked={
                !!filtersToApply.find((curFilter) => curFilter.id === filter.id)
              }
            />
            {!!filter?.totalCount && (
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  subFilterSelect(
                    filter,
                    selectedCategory?.parentCategory || ""
                  )
                }
                after={<Icon name="line-arrow-down" rotateAngle={-90} />}
              />
            )}
          </div>
        ))}
        {isPaginationLoading && (
          <div className={styles.loaderWrapper}>
            <Loader size="small" />
          </div>
        )}
      </div>
    </div>
  </>
)

export default CategoryList
