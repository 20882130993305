import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"
import Loader from "@components/ui/Loader/BarLoader"

import SolutionsCardContainer from "./SolutionsCardContainer"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"

import styles from "./AvailableSolutions.module.sass"

const AvailableSolutions: React.FC = observer(() => {
  const navigate = useNavigate()
  const { solutionsController } = useController()
  const { avatar } = useAvatarScope()
  const { solutionsStore } = useStore()
  const location = useLocation()

  const handleLaunch = (solution: SolutionData) => {
    solutionsStore.setSolution(solution)
    navigate(mainRoutes.solutionView(solution.workflowType))
  }

  if (solutionsStore.isLoading && !solutionsStore.solutions?.length) {
    return (
      <div className={styles.loader}>
        <Loader size="large" />
      </div>
    )
  }

  useEffect(() => {
    const solutionId = new URLSearchParams(location.search).get("solutions")
    const solution = solutionsStore?.solutions?.find(
      (item) => item.id === solutionId
    )
    if (solution) {
      handleLaunch(solution)
    }
  }, [location.search, solutionsStore.solutions])

  return (
    <div className={styles.root}>
      <SolutionsCardContainer onLaunch={handleLaunch} />
    </div>
  )
})

export default AvailableSolutions
