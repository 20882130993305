import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import toString from "lodash/toString"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useTooltip from "@components/hooks/useTooltip"

import { useMatrixContext } from "./MatrixContext"
import {
  stringifyPoint,
  pointToCode,
  includes,
  isValidHttpUrl,
  isFilename,
} from "./utils"
import Icon from "../Icon/Icon"
import TruncatedText from "../Typography/TruncatedText"
import MagicTextLoader from "../Loader/MagicTextLoader"
import useContextMenu from "../ContextMenu/useContextMenu"
import Popper from "../Dropdown/Popper"
import CellLinkTooltip from "./Tooltips/CellLinkTooltip"
import ErrorTooltip from "./Tooltips/ErrorTooltip"
import FileChip from "./FileChip"
import { CellFormat } from "./types"
import { DEFAULT_ALIGN, DEFAULT_TEXT_SIZE } from "./constants"

import styles from "./Cell.module.sass"

type CellProps = {
  columnIndex: number
  rowIndex: number
}

const Cell: React.FC<CellProps> = observer(({ columnIndex, rowIndex }) => {
  const contextMenu = useContextMenu()

  const context = useMatrixContext()

  const {
    editManager,
    selectedRange,
    spreadRange,
    contextMenuManager,
    updateSpreading,
  } = context

  const longTextModal = useModal(ModalsTypes.LONG_TEXT_MODAL)

  const currentCellPoint = { x: columnIndex, y: rowIndex }

  const handleMouseDown = (e: React.MouseEvent) => {
    editManager.submitCell()

    if (e.button === 2) {
      if (!includes(selectedRange.range, currentCellPoint)) {
        selectedRange.startRange(currentCellPoint)
      }
      e.stopPropagation()
      return
    }

    selectedRange.startRange(currentCellPoint)
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (selectedRange.rangeStarted) {
      selectedRange.updateRange(currentCellPoint)
    }
    if (spreadRange?.rangeStarted) {
      updateSpreading(currentCellPoint)
    }
  }

  const handleMouseDoubleClick = (e: React.MouseEvent) => {
    editManager.editCell(currentCellPoint)
  }

  const cell = editManager.findCell(currentCellPoint)

  const content = cell?.value == null ? "" : toString(cell.value)

  const isFailed = !!cell?.state.error
  const isLink = !isFailed && isValidHttpUrl(content)

  const [linkNode, setLinkNode] = React.useState<HTMLElement | null>(null)
  const [linkContainerNode, setLinkContainerNode] =
    React.useState<HTMLElement | null>(null)
  const linkTooltip = useTooltip(linkContainerNode, linkNode, {
    placement: "bottom-start",
    disabled: !isLink,
  })

  const [errorNode, setErrorNode] = React.useState<HTMLElement | null>(null)
  const [errorContainer, setErrorContainer] =
    React.useState<HTMLElement | null>(null)
  const errorTooltip = useTooltip(errorContainer, errorNode, {
    placement: "left-start",
    disabled: !isFailed,
  })

  const cellFormatting = useCellFormat(cell?.format)

  const withAutocomplete =
    cell?.validationRule?.type === "OPTION" ||
    cell?.validationRule?.type === "AUTOCOMPLETE"

  const renderValue = () => {
    if (isFilename(content)) return <FileChip name={content} />

    const truncated = content?.length > 450

    return (
      <TruncatedText
        maxLines={2}
        truncated={truncated}
        onReadMoreClick={() =>
          longTextModal.showModal({
            title: pointToCode(currentCellPoint),
            content,
          })
        }
      >
        {content}
      </TruncatedText>
    )
  }

  return (
    <>
      <div
        ref={setLinkContainerNode}
        className={clsx(
          styles.root,
          {
            [styles.withError]: isFailed,
            [styles.underlined]: isLink,
          },
          cellFormatting.classNames
        )}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onDoubleClick={handleMouseDoubleClick}
        onContextMenu={(e) => {
          if (e.shiftKey) return
          const menu = contextMenuManager.getContextMenu()
          if (menu) contextMenu.handleContextMenu(e, menu)
        }}
        aria-label={stringifyPoint(currentCellPoint)}
        role="button"
        tabIndex={-1}
        style={cellFormatting.style}
      >
        {cell != null && (
          <>
            <div className={clsx(styles.content)}>
              {cell.isLoading ? (
                <MagicTextLoader>Processing...</MagicTextLoader>
              ) : (
                renderValue()
              )}
            </div>

            {withAutocomplete && (
              <button
                type="button"
                className={clsx(styles.arrow)}
                onClick={(e) => {
                  editManager.editCell(currentCellPoint)
                  e.stopPropagation()
                }}
              >
                <Icon name="arrow-down" />
              </button>
            )}
          </>
        )}

        {isFailed && (
          <span className={styles.error} ref={setErrorContainer}>
            !
          </span>
        )}
      </div>

      <Popper
        ref={setLinkNode}
        style={linkTooltip.style}
        isActive={linkTooltip.isActive}
      >
        <CellLinkTooltip url={content} />
      </Popper>

      <Popper
        ref={setErrorNode}
        style={errorTooltip.style}
        isActive={errorTooltip.isActive}
      >
        <ErrorTooltip>{cell?.state.error || "Unexpected error"}</ErrorTooltip>
      </Popper>
    </>
  )
})

export default Cell

const useCellFormat = (format: Partial<CellFormat> = {}) => {
  const vAlign = format?.vAlignment ?? DEFAULT_ALIGN
  const hAlign = format?.hAlignment ?? DEFAULT_ALIGN
  const textSize = format?.textSize ?? DEFAULT_TEXT_SIZE
  const textDecoration = format?.textDecoration
  const backgroundColor = format?.backgroundColor
  const textColor = format?.textColor

  return {
    classNames: clsx(
      styles[`v-align-${vAlign}`],
      styles[`h-align-${hAlign}`],
      styles[`text-${textSize}`],
      ...(textDecoration?.map((it) => styles[`text-${it}`]) ?? [])
    ),
    style: { backgroundColor, color: textColor },
  }
}
