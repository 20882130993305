/* eslint-disable camelcase */
import { AxiosResponse } from "axios"
import { Observable } from "rxjs"

import {
  BusinessUnit,
  Company,
  CompanyTheme,
  DocumentComplianceReport,
  Filename,
  ProductThemeType,
} from "@framework/types/company"
import {
  GetComparisonReportParams,
  ListResponse,
  Product,
  ProductQueryRequest,
  ProductQueryResponse,
} from "@framework/types/product"
import { ProductFilter, SearchFilter } from "@framework/types/product-filter"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetProductsResponse extends DefaultSuccessResponse {
  data: ProductQueryResponse
}

export interface GetProductByIdResponse extends DefaultSuccessResponse {
  data: Product
}

export interface GetPopularProductsResponse extends DefaultSuccessResponse {
  data: ListResponse<Product>
}

export interface GetNewProductsResponse extends DefaultSuccessResponse {
  data: ListResponse<Product>
}

export interface GetCompanyThemesResponse extends DefaultSuccessResponse {
  data: CompanyTheme[]
}

export interface GetDocumentComplianceReportResponse
  extends DefaultSuccessResponse {
  data: DocumentComplianceReport
}

class ProductAPI extends HttpService {
  getAllProducts = (
    filter: ProductQueryRequest
  ): Promise<AxiosResponse<GetProductsResponse>> =>
    this.get("ts/products/query", true, filter)

  getProductById = (
    productId: string
  ): Promise<AxiosResponse<GetProductByIdResponse>> =>
    this.get(`ts/products/${productId}`)

  getPopularProducts = (): Promise<AxiosResponse<GetPopularProductsResponse>> =>
    this.get("ts/products/popular")

  getNewProducts = (): Promise<AxiosResponse<GetNewProductsResponse>> =>
    this.get("ts/products/new")

  getProductFilters = (): Promise<AxiosResponse<ListResponse<ProductFilter>>> =>
    this.get("ts/products/filters")

  getProducts$ = (params: {
    name?: string
    companyIds?: string[]
    pageSize?: number
    pageNum?: number
    filters?: SearchFilter[]
    excludeCompanyIds?: string[]
    excludeIds?: string[]
    // pageToken?: string
  }): Observable<AxiosResponse<ListResponse<Product>>> =>
    this.postStream$("ts/products/search", params)

  getRecommendedProducts = (params: {
    companyIds?: string[]
    excludeCompanyIds?: string[]
    requirementText: string
    requirementFile: File
  }): Promise<AxiosResponse<ListResponse<Product>>> => {
    const data = new FormData()
    data.set("companyIds", JSON.stringify(params.companyIds ?? []))
    data.set(
      "excludeCompanyIds",
      JSON.stringify(params.excludeCompanyIds ?? [])
    )
    data.set("requirementText", params.requirementText)
    data.set("requirementFile", params.requirementFile)

    return this.post("ts/products/recommendations", params)
  }

  getTargetCompanies = (): Promise<AxiosResponse<ListResponse<Company>>> =>
    this.get("ts/companies")

  getTargetFiles = (
    query?: string,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ListResponse<Filename>>> =>
    this.get("ts/data/file/manual/all", true, { query }, null, signal)

  getBusinessUnitList = (
    companyId: string
  ): Promise<AxiosResponse<ListResponse<BusinessUnit>>> =>
    this.get(`ts/companies/${companyId}/business-units`)

  getCompanyThemes = (
    companyId: string,
    options: { businessUnit?: string; type?: ProductThemeType } = {}
  ): Promise<AxiosResponse<GetCompanyThemesResponse>> =>
    this.get(`ts/companies/${companyId}/themes`, true, options)

  generateThemes = (options: {
    companyName: string
    category: string
    businessUnit?: string
  }): Promise<AxiosResponse<GetCompanyThemesResponse>> =>
    this.post(`ts/companies/themes`, options)

  generateDocumentComplianceReport = (options: {
    parentDocument: string
    childDocument: string
  }): Promise<AxiosResponse<string>> =>
    this.post("ts/companies/document-compliance", options)

  getComparisonReport$ = (
    params: GetComparisonReportParams
  ): Observable<AxiosResponse<{ report: string }>> => {
    const data = new FormData()

    data.set("targetCompanyId", params.targetCompanyId)
    data.set("productIds", JSON.stringify(params.productIds))
    data.set("baseCompanyId", params.baseCompanyId)
    if (params.requirementFile)
      data.set("requirementFile", params.requirementFile)
    if (params.businessUnit) data.set("businessUnit", params.businessUnit)
    if (params.requirementText) {
      data.set("requirementText", params.requirementText)
    }

    return this.postStream$("ts/products/comparison-report", data)
  }
}

export default new ProductAPI()
