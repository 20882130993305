import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import styles from "./RecentActivity.module.sass"

export type RecentActivityProps = {
  onClose: () => void
}

const RecentActivity: React.FC<RecentActivityProps> = observer(
  ({ onClose }) => {
    const {
      searchSuggestionStore: {
        favoriteQuestions,
        isQuestionsLoading,
        loadQuestions,
      },
      solutionsStore,
      factFinderSolutionStore: { searchFlowStore },
    } = useStore()
    const { solution } = solutionsStore
    const { avatar } = useActiveAvatar()
    const modal = useModal(ModalsTypes.CONFIRM_MODAL)

    const { factFinderSolutionController } = useController()

    const askRecentQuestion = (query: string) => {
      solutionsStore.showRecentActivity = false
      searchFlowStore.reset()
      if (solution?.id)
        factFinderSolutionController.search(query, avatar, solution.id, [])
    }

    const handleClick = (query: string) => {
      if (!searchFlowStore?.flowSequence?.length) askRecentQuestion(query)
      else
        modal.showModal({
          message: (
            <>
              Are you sure you want to continue?
              <br /> it will reset current search results!
            </>
          ),
          confirm: "positive",
          onConfirm: () => askRecentQuestion(query),
        })
    }
    useEffect(() => {
      if (avatar && solution?.id) {
        loadQuestions(avatar.id, solution.id)
      }
    }, [avatar, solution?.id])
    return (
      <div className={styles.root}>
        <Text
          className={styles.header}
          variant="h3"
          weight="bold"
          align="center"
        >
          Your Recent Activity
        </Text>
        <div className={styles.list}>
          {isQuestionsLoading && <Skeleton count={5} lineHeight={50} />}
          {favoriteQuestions.map((question) => (
            <div
              tabIndex={0}
              onKeyPress={() => {}}
              role="button"
              key={question.index}
              onClick={() => handleClick(question.value)}
              className={styles.question}
            >
              <Text variant="body1">{question.value}</Text>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <Button color="primary" size="small" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    )
  }
)
export default RecentActivity
