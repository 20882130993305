import { SolutionTypes } from "@framework/constants/search-results"

export interface SolutionSnippetData {
  id: string
  tag: string
  title: string
  description: string
}

export interface SolutionEntity {
  createdAt: Date
  description: string
  category: ProductSolutionCategory
  id: string
  lastUpdatedAt: Date
  name: string
  key: SolutionTypes | string
  workflowType: WorkFlowType
  image: string
  suggestedQuestions: string[]
}

export interface SolutionCategory {
  category: string
  heading: string
  description: string
}

export enum ProductSolutionCategory {
  GENERAL = "general",
  PROSPECT = "prospect",
  SELL = "sell",
  LEARN = "learn",
}

export enum WorkFlowType {
  SEARCH = "Search",
  WORKBOOK = "Workbook",
  VIEW = "View",
  PRODUCT_COMPARISON = "Product Comparison",
}

export interface SolutionData extends SolutionEntity {}
