import React, { ReactNode, useEffect, useRef, useState } from "react"
import clsx from "clsx"

import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import HTMLText from "@components/ui/Typography/HTMLText"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Button from "@components/ui/Button/Button"
import { AnswerItem } from "@framework/types/search"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"

import ImagePreview from "./components/ImagePreview"
import ExpertAnswer from "./components/ExpertAnswer"

import styles from "./AnswerOriginalView.module.sass"

export type ImageDetails = {
  url: string
  title: string
  citation: number
  id: string
  description?: string
  isExpertAnswer?: boolean
  details: AnswerItem
}
interface PDFViewerProps {
  images: ImageDetails[]
  onSelect: (imageDetails: ImageDetails) => void
}

const AnswerOriginalView: React.FC<PDFViewerProps> = ({
  images = [],
  onSelect,
}) => {
  const { answerIndex } = useSearchBlock(SearchSummaryBlockStore)
  const [scale, setScale] = useState<number>(90)
  const index = Number(answerIndex || 1) - 1
  const [image, setImage] = useState<ImageDetails>(images[index])
  const { isOpened, handleToggle } = useToggle()
  const [showText, setShowText] = useState(false)
  const previewListRef = useRef<HTMLDivElement>(null)
  const [imageHeight, setImageHeight] = useState<number | null>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const handleScaleUp = () => {
    setScale((currScale) => (currScale < 170 ? currScale + 10 : currScale))
  }

  const handleScaleDown = () => {
    setScale((currScale) => (currScale > 20 ? currScale - 10 : currScale))
  }

  const handleImageChange = (newImage: ImageDetails) => {
    setImage(newImage)
    onSelect(newImage)
  }

  const toggleTextView = () => {
    setShowText((p) => !p)
  }

  useEffect(() => {
    setScale(90)
    if (isOpened && !image?.url) {
      handleToggle()
    }
  }, [image])

  useEffect(() => {
    setImage(images[index])
    onSelect(images[index])
    if (previewListRef.current) {
      const selectedImageElement = previewListRef.current.children[index]
      if (selectedImageElement) {
        selectedImageElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        })
      }
    }
  }, [])

  useEffect(() => {
    const updateImageHeight = () => {
      if (imageRef.current) {
        setImageHeight(imageRef.current.clientHeight)
      }
    }

    updateImageHeight()
    window.addEventListener("resize", updateImageHeight)

    return () => {
      window.removeEventListener("resize", updateImageHeight)
    }
  }, [image, scale, isOpened])

  const scrollPreviewList = (direction: "up" | "down") => {
    if (previewListRef.current) {
      const itemHeight = previewListRef.current.children[0]?.clientHeight || 200
      const scrollAmount = direction === "up" ? -itemHeight : itemHeight
      previewListRef.current.scrollBy({ top: scrollAmount, behavior: "smooth" })
    }
  }

  const getSideBarHeight = () => {
    if (isOpened) return "97vh"
    return imageHeight ? `${imageHeight}px` : "700px"
  }
  return (
    <div className={clsx(styles.root, { [styles.fullscreen]: isOpened })}>
      <div className={styles.view}>
        <div
          className={clsx(styles.imageContainer, {
            [styles.imageScroll]: isOpened,
          })}
        >
          {image?.url && (
            <img
              ref={imageRef}
              style={{
                width: isOpened ? `${scale}%` : "100%",
              }}
              src={image?.url}
              alt={image?.url}
            />
          )}
          {!isOpened &&
            !image?.isExpertAnswer &&
            (image?.url ? (
              <Button
                color="secondary"
                size="small"
                className={styles.textViewToggleButton}
                onClick={toggleTextView}
                after={
                  <Icon
                    name="line-arrow-down"
                    rotateAngle={showText ? 180 : 0}
                  />
                }
              >
                Extracted Text
              </Button>
            ) : (
              <Text className={styles.extractedTextHeading} variant="body1">
                Extracted Text
              </Text>
            ))}
          {image?.isExpertAnswer && <ExpertAnswer details={image?.details} />}

          {!isOpened &&
            (showText || !image?.url) &&
            (image?.isExpertAnswer ? (
              <HTMLText className={styles.textContent}>
                {image?.description}
              </HTMLText>
            ) : (
              <TextResult
                className={styles.textContent}
                content={image?.description}
              />
            ))}
        </div>

        {image?.url && (
          <div className={styles.control}>
            <Icon
              onClick={handleToggle}
              className={styles.controlButton}
              name={isOpened ? "minimize" : "maximize"}
            />
            {isOpened && (
              <>
                <Icon
                  onClick={handleScaleUp}
                  className={styles.controlButton}
                  name="zoom-in"
                />
                <Icon
                  onClick={handleScaleDown}
                  className={styles.controlButton}
                  name="zoom-out"
                />
              </>
            )}
          </div>
        )}
      </div>

      <div
        className={styles.sidebar}
        style={{
          height: getSideBarHeight(),
        }}
      >
        <Button
          size="small"
          variant="text"
          onClick={() => scrollPreviewList("up")}
        >
          <Icon
            name="arrow-down"
            rotateAngle={180}
            className={styles.scrollButton}
          />
        </Button>
        <div className={styles.previewList} ref={previewListRef}>
          {images.map((item) => (
            <div key={item.id} className={styles.imagePreviewContainer}>
              <Text variant="h4">
                {item.title}{" "}
                <Chip
                  className={styles.citationNumber}
                  variant="default"
                  color="primary-solid"
                >
                  {item.citation}
                </Chip>
              </Text>
              <ImagePreview
                src={item.url}
                checked={image?.id === item.id}
                onClick={() => handleImageChange(item)}
              />
            </div>
          ))}
        </div>
        <Button
          size="small"
          variant="text"
          onClick={() => scrollPreviewList("down")}
        >
          <Icon name="arrow-down" className={styles.scrollButton} />
        </Button>
      </div>
    </div>
  )
}

export default AnswerOriginalView
