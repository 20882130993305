import { GetFilterRequest } from "@framework/types/query-filter"

export const DefaultFilters: GetFilterRequest = {
  filters: [
    {
      type: "product",
      page: 1,
      pageSize: 10,
      search: "",
    },
    {
      type: "datatype",
      page: 1,
      pageSize: 10,
      search: "",
    },
    {
      type: "datasource",
      page: 1,
      pageSize: 10,
      search: "",
    },
    {
      type: "category",
      page: 1,
      pageSize: 10,
      search: "",
    },
  ],
}

export default DefaultFilters
