import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { NotificationBadge } from "@components/ui/Loader/BadgeWrapper/BadgeWrapper"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useNavigationScope from "@components/layout/NavigationScopeContext/useNavigationScope"
import { Option } from "@framework/types/utils"

import Profile from "./Profile/Profile"
import NavItem from "./NavItem/NavItem"
import ExpandableNavItem from "../Sidebar/NavItem/ExandableNavItem"

import styles from "./MobileSideMenu.module.sass"

export interface MobileSideMenuProps {
  isOpened?: boolean
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
  isOpened = true,
}) => {
  const {
    allowedNavOptions: menuOptions,
    allowedProfileOptions: profileOptions,
  } = useNavigationScope()
  const { showModal } = useModal(ModalsTypes.LOGOUT_MODAL)
  const [selectedOption, setSelectedOption] = useState<Option | null>()

  const handleLogoutClick = () => showModal({})

  return (
    <div className={clsx(styles.root, { [styles.opened]: isOpened })}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <Profile menuOptions={profileOptions} />
        </div>

        <div className={styles.expandableMenu}>
          {menuOptions.map((item) => (
            <ExpandableNavItem
              active={item === selectedOption}
              key={item.name}
              option={item}
              parentOption={item}
              setSelectedOption={setSelectedOption}
              setSideBarOpen={() => {}}
            />
          ))}
        </div>

        <div className={styles.footer}>
          <NavItem
            to="/login"
            label="logout"
            icon="log-out"
            className={styles.logout}
            onClick={handleLogoutClick}
            key="logout"
          />
        </div>
      </div>
    </div>
  )
}

type NavItemBadgeProps = {
  name: string
}

export const NavItemBadge: React.FC<NavItemBadgeProps> = observer(
  ({ name }) => {
    const {
      updateStore: { hasChanges },
    } = useStore()
    if (name === "/updates" && hasChanges) return <NotificationBadge />
    return null
  }
)

export default MobileSideMenu
