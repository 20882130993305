import { makeAutoObservable } from "mobx"
import keyBy from "lodash/keyBy"
import uniqBy from "lodash/uniqBy"
import concat from "lodash/concat"

import {
  ContextMenuOptionHandler,
  Menu,
  MenuGroup,
} from "@components/ui/ContextMenu/types"
import { Option } from "@framework/types/utils"

import MatrixStore from "./MatrixStore"

export type IContextMenuBuilder = (context: MatrixStore) => Menu

export class ContextMenuManager {
  // injections
  private context: MatrixStore

  // state

  cellContextMenuBuilder: IContextMenuBuilder[]

  constructor(injections: { context: MatrixStore }) {
    this.context = injections.context

    this.cellContextMenuBuilder = [
      utilsCellContextMenuBuilder,
      defaultCellContextMenuBuilder,
    ]

    makeAutoObservable(this, { cellContextMenuBuilder: false })
  }

  getContextMenu = () => {
    return buildMenu(this.cellContextMenuBuilder, this.context)
  }

  addCellContextMenuBuilder = (builder: IContextMenuBuilder) => {
    this.cellContextMenuBuilder.push(builder)
  }

  removeCellContextMenuBuilder = (builder: IContextMenuBuilder) => {
    this.cellContextMenuBuilder = this.cellContextMenuBuilder.filter(
      // eslint-disable-next-line eqeqeq
      (it) => it != builder
    )
  }
}

export default ContextMenuManager

// Utils

const buildMenu = (
  menus: IContextMenuBuilder[],
  context: MatrixStore
): Menu | null => {
  if (menus.length === 0) return null

  return menus.reduce((acc: Menu | null, item, index): Menu => {
    const menu = item(context)

    if (index === 0) return menu

    if (acc == null) throw new Error("Failed to init context menu")

    return {
      groups: mergeMenuGroups(acc.groups, menu.groups),
      handler: mergeMenuHandlers(acc.handler, menu.handler),
    }
  }, null)
}

const mergeMenuHandlers =
  (
    h1: ContextMenuOptionHandler,
    h2: ContextMenuOptionHandler
  ): ContextMenuOptionHandler =>
  (it: Option) =>
    h1(it) || h2(it)

const mergeMenuGroups = (g1: MenuGroup[], g2: MenuGroup[]): MenuGroup[] => {
  const m1Map = keyBy(g1, "name")
  const m2Map = keyBy(g2, "name")

  const groups = concat(
    g2.map((it) => it.name),
    g1.map((it) => it.name)
  )

  return groups.map((groupName) => {
    const m1Group = m1Map[groupName]
    const m2Group = m2Map[groupName]

    if (m2Group == null) return { ...m1Group }
    if (m1Group == null) return { ...m2Group }

    return {
      name: groupName,
      label: m1Group.label,
      options: uniqBy(concat(m1Group.options, m2Group.options), "name"),
    }
  })
}

// Constants

const defaultCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CUT":
        context.editManager.cut()
        break

      case "COPY":
        context.editManager.copy()
        break

      case "PASTE":
        context.editManager.paste()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "DEFAULT",
      options: [
        { name: "CUT", value: "Cut", icon: "scissors" },
        { name: "COPY", value: "Copy", icon: "checkbox-multiple-blank" },
        { name: "PASTE", value: "Paste", icon: "clipboard" },
      ],
    },
  ],
})

const utilsCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CLEAN_UP":
        context.editManager.reset()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "UTILS",
      options: [
        {
          name: "CLEAN_UP",
          value: "Clean Up",
          icon: "trash-can",
        },
      ],
    },
  ],
})
