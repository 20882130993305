import React from "react"
import isEmpty from "lodash/isEmpty"
import { observer } from "mobx-react-lite"

import AlertBanner from "@components/ui/AlertBanner/AlertBanner"
import { useStore } from "@store/index"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import BlockCard from "@pages/search/SearchFlow/BlockCard"
import Text from "@components/ui/Typography/Text"
import MarkdownWithCitations from "@components/prototypes/ResultsCard/AnswerSection/MarkdownWithCitations"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import AnswerControl from "./AnswerControl"

import styles from "./AnswerSummary.module.sass"

type AnswerSummaryPassageProps = {
  hidePostToExpert?: boolean
}

const AnswerSummaryPassage: React.FC<AnswerSummaryPassageProps> = observer(
  ({ hidePostToExpert = false }) => {
    const {
      searchEntityBlock,
      searchEntity,
      searchBlockId: blockId,
    } = useSearchBlock(SearchSummaryBlockStore)

    const { restrictionsStore: access } = useStore()
    const { avatar } = useActiveAvatar()

    const { searchSources, searchSummary: summary } = searchEntityBlock

    const isSummaryDataReady = summary.isLoaded

    const isSummaryLoading =
      !summary.isLoaded && summary.isLoading && searchEntity.isLoading

    const isPassagesDataReady = searchEntityBlock.searchAnswersData != null

    const isPassagesLoading =
      searchEntityBlock.searchAnswersData == null && searchEntity.isLoading

    const getCitationLink = (index: string) =>
      `${searchEntity.id}/${blockId}/${index}`

    if (!isSummaryDataReady || searchEntity.error) return null

    return (
      <div className={styles.avatarSummary}>
        <BlockCard className={styles.summary}>
          <Text
            className={styles.summaryPassage}
            variant="body2"
            color="text70Color"
          >
            {isSummaryLoading ? (
              <Skeleton count={5} />
            ) : (
              <MarkdownWithCitations citationLink={getCitationLink}>
                {summary.summary || "No summary"}
              </MarkdownWithCitations>
            )}
          </Text>

          {summary.showAttachmentTruncationWarning && (
            <AlertBanner type="info">
              Summary may be incomplete due to content exceeding model limit
            </AlertBanner>
          )}

          {access.showAIGeneratedSummaryAlert && !!summary.summary && (
            <AlertBanner>
              This is a Beta version of an AI-generated summary.
            </AlertBanner>
          )}
          {!searchEntity.isLoading && (
            <AnswerControl hidePostToExpert={hidePostToExpert} />
          )}

          {isPassagesDataReady ? null : isPassagesLoading ||
            isEmpty(searchSources?.sourcesList) ? null : (
            <AlertBanner type="error">
              Failed to load summary sources
            </AlertBanner>
          )}
        </BlockCard>
      </div>
    )
  }
)
export default AnswerSummaryPassage
