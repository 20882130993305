import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"

import SuggestedQuestions from "./SuggestedQuestions"

import styles from "./AppControlContainer.module.sass"

type AppControlContainerProps = {
  moveDown: boolean
  title?: string
  description?: string
  hideRecentQuestions?: boolean
}

const AppControlContainer: React.FC<AppControlContainerProps> = observer(
  ({ moveDown, title, description, children, hideRecentQuestions = false }) => {
    return (
      <div className={clsx(styles.root, { [styles.down]: moveDown })}>
        <div className={styles.centerText}>
          {!moveDown && (
            <div className={styles.header}>
              <Text variant="h2" align="center" weight="semibold">
                {title}
              </Text>
              <Text align="center" color="text70Color" weight="normal">
                {description}
              </Text>
            </div>
          )}

          {children}

          {!moveDown && !hideRecentQuestions && (
            <div className={styles.footer}>
              <SuggestedQuestions />
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default AppControlContainer
