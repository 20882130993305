import React from "react"

import { calcPosition } from "./utils"

export type Alignment = "start" | "end"
export type Placement = "top" | "right" | "bottom" | "left"
export type PopperPlacement = `${Placement}-${Alignment}`

export interface ContainerProps {
  placement?: PopperPlacement
  disabled?: boolean
  delay?: number
}

const DELAY = 500

export const useTooltip = (
  containerRef: HTMLElement | null,
  popperRef: HTMLElement | null,
  {
    placement = "bottom-start",
    disabled = false,
    delay = DELAY,
  }: ContainerProps = {}
) => {
  if (delay < 0) throw Error("Tooltip delay should be >= 0")

  const [isOpened, setIsOpened] = React.useState<boolean>(true)

  const [pos, setPos] = React.useState<React.CSSProperties>({
    position: "fixed",
  })

  React.useLayoutEffect(() => {
    if (!containerRef || disabled) {
      setIsOpened(false)
      return undefined
    }

    let timer: NodeJS.Timeout | null = null

    const resetTimer = () => {
      if (timer == null) return
      clearTimeout(timer)
      timer = null
    }

    // is closed
    if (!isOpened) {
      if (timer == null) {
        const handleLeave = () => {
          containerRef.removeEventListener("mouseleave", handleLeave)
          resetTimer()
        }

        const handleEnter = (e: Event) => {
          e.stopPropagation()

          if (timer != null) return

          timer = setTimeout(() => setIsOpened(true), delay)

          containerRef.addEventListener("mouseleave", handleLeave)
        }

        containerRef.addEventListener("mouseenter", handleEnter)
        return () => {
          resetTimer()
          containerRef.removeEventListener("mouseenter", handleEnter)
          containerRef.removeEventListener("mouseleave", handleLeave)
        }
      }
    }

    // is opened
    const handleMouseMove = (e: Event) => {
      if (
        containerRef.contains(e.target as Node) ||
        popperRef?.contains(e.target as Node)
      ) {
        resetTimer()
        return
      }

      if (timer != null) return
      timer = setTimeout(() => setIsOpened(false), delay)
    }

    const handleClick = (e: Event) => {
      if (
        !containerRef.contains(e.target as Node) &&
        !popperRef?.contains(e.target as Node)
      ) {
        setIsOpened(false)
      }
    }

    const instantClose = () => setIsOpened(false)

    document.addEventListener("mousedown", handleClick)
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("scroll", instantClose, true)

    return () => {
      document.removeEventListener("mousedown", handleClick)
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("scroll", instantClose, true)
      resetTimer()
    }
  }, [containerRef, popperRef, isOpened, disabled])

  React.useLayoutEffect(() => {
    if (!containerRef || !popperRef || !isOpened || disabled) return

    const contRect = containerRef.getBoundingClientRect()
    const popRect = popperRef.getBoundingClientRect()

    const [place, align] = placement.split("-")

    setPos({
      position: "fixed",
      ...calcPosition(contRect, popRect, place, align, 0),
    })
  }, [placement, containerRef, popperRef, isOpened, disabled])

  React.useLayoutEffect(() => {
    if (disabled) setIsOpened(false)
  }, [disabled])

  return {
    isActive: isOpened,
    style: pos as React.CSSProperties,
  }
}

export default useTooltip
