/* eslint-disable no-underscore-dangle */
import { makeAutoObservable, toJS } from "mobx"
import intersection from "lodash/intersection"

import type { AppRestrictions } from "@framework/types/app"
import RootStore from "@store/RootStore"
import config from "@root/config"

import { dataSourcesRestrictions } from "./constants"
import { extractSourceTypeBlackList } from "./utils"

// turn true to cancel all restrictions
const isFullAccess = config.FULL_ACCESS_MODE
const isALLFeaturesAvailable = config.ALL_FEATURES_ENABLED || isFullAccess
const isAllActionsAvailable = config.ALL_ACTIONS_ENABLED || isFullAccess

export class RestrictionsStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get userActions() {
    if (this.rootStore.userStore.userActions == null) return null
    return new Set(
      Object.values(this.rootStore.userStore.userActions).map((it) =>
        it.toLowerCase()
      )
    )
  }

  get config(): AppRestrictions {
    return this.rootStore.appStore.config
  }

  get shouldOpenSolutionOnLogin() {
    return this.testFlag("openSolutionsOnLogin")
  }

  get canEditAvatar() {
    return this.testAction("CREATE_AVATAR")
  }

  get isAvatarsAvailable() {
    return this.testFlag("showChannels") && this.testAction("VIEW_AVATARS")
  }

  get canAssignDataToAvatar() {
    return (
      this.testFlag("showChannels") && this.testAction("ASSIGN_DATA_AVATAR")
    )
  }

  get isQuestionsSectionAvailable() {
    return (
      this.testFlag("showKnowledgeAnalytics") &&
      this.testAction("VIEW_KNOWLEDGE_ANALYTICS")
    )
  }

  get isAppUsageAnalyticsAvailable() {
    return (
      this.testFlag("showUsageAnalytics") &&
      this.testAction("VIEW_USAGE_ANALYTICS")
    )
  }

  get canAccessAPIKeys() {
    return this.testAction("VIEW_API_KEY")
  }

  get isAPIUsageAnalyticsAvailable() {
    return (
      this.testFlag("showAPIUsageAnalytics") &&
      this.testAction("VIEW_API_LOG_ANALYTICS")
    )
  }

  get isLatestUpdatesAvailable() {
    return this.testFlag("showLatestUpdates")
  }

  get tableViewResultIsImage() {
    return this.testFlag("showTableScreenShot")
  }

  get canEditDataSource() {
    return this.testAction("UPLOAD_DATA")
  }

  get canDeleteDataSource() {
    return this.testAction("DELETE_DATA")
  }

  get isDataSourcesAvailable() {
    return (
      this.testFlag("showDataExplorer") && this.testAction("VIEW_DATA_SOURCES")
    )
  }

  get canInviteUsers() {
    return this.testAction("CREATE_USER")
  }

  get canDeleteUser() {
    return this.testAction("DELETE_USER")
  }

  get isNeshStatusAvailable() {
    return this.testAction("VIEW_NESH_STATUS")
  }

  get isAnswerSharingAvailable() {
    return this.testFlag("showSharing")
  }

  get canAssignAnswerToExpert() {
    return this.testFlag("showAnswerFeedback")
  }

  get isAnswerMetadataAvailable() {
    return this.testFlag("showMetadata")
  }

  get isConfigDrivenAuth() {
    return this.testFlag("enableConfigDrivenAuth")
  }

  /**
   * @deprecated
   */
  get isSSOAvailable() {
    return this.testFlag("isSSOEnabled")
  }

  /**
   * @deprecated
   */
  get isSSOTypeSAMLAvailable() {
    return this.testFlag("enableSAMLSSO")
  }

  get isAdminPanelAvailable() {
    return this.testAction("VIEW_ADMIN_PANEL")
  }

  get isExtendedResultAvailable() {
    return !this.testFlag("disableSearchExtendedView")
  }

  get isInformalKnowledgeSectionAvailable() {
    return !this.testFlag("disableInformalKnowledgeSection")
  }

  get isExpertKnowledgeSectionAvailable() {
    return !this.testFlag("disableExpertKnowledgeSection")
  }

  get isAnswersSortingByModifiedDate() {
    return this.testFlag("showSortModifiedDateOption")
  }

  get onlySuperAdminAccess() {
    return this.rootStore.userStore.isSuperAdmin
  }

  get isUsersListAvailable() {
    return this.testAction("VIEW_USERS")
  }

  get canAddAction() {
    return this.testAction("ADD_ACTION")
  }

  get canUpdateAction() {
    return this.testAction("UPDATE_ACTION")
  }

  get canAddRole() {
    return this.testAction("ADD_ROLE")
  }

  get canUpdateRole() {
    return this.testAction("UPDATE_ROLE")
  }

  get canResetPassword() {
    return this.testAction("RESET_USER_PASSWORD")
  }

  get showAIGeneratedSummaryAlert() {
    return this.testFlag("showSummaryAIAlert")
  }

  get isGlossaryAvailable() {
    return (
      this.testFlag("showGlossary") && this.testAction("VIEW_WORD_GLOSSARY")
    )
  }

  get canEditGlossary() {
    return this.testAction("UPDATE_WORD_GLOSSARY")
  }

  get isGlossaryUploadEnabled() {
    return this.testFlag("enableGlossaryUpload")
  }

  get isVoiceRecognitionEnabled() {
    return this.testFlag("enableVoiceRecognizer")
  }

  get isSearchVoiceRecognitionEnabled() {
    return this.testFlag("enableSearchVoiceRecognizer")
  }

  get isExpertInsightsAvailable() {
    return (
      this.testFlag("enableExpertInsights") &&
      this.testAction("VIEW_EXPERT_INSIGHT_PANEL")
    )
  }

  get canCreateExpertArticle() {
    return this.testAction("ADD_ARTICLE")
  }

  get canCreateExpertArticleInsights() {
    return this.testAction("ADD_INSIGHT")
  }

  get canUpdateExpertArticleInsights() {
    return this.testAction("UPDATE_INSIGHT")
  }

  get canDeleteExpertArticleInsights() {
    return this.testAction("DELETE_INSIGHT")
  }

  get isAppAgreementsStampAvailable() {
    return !this.testFlag("hideAppAgreementsStamp")
  }

  get canSwitchTheme() {
    return this.testFlag("allowThemeSwitch")
  }

  get isIEEEUsabillaScriptAllowed() {
    return this.testFlag("allowIEEEUsabillaScript")
  }

  get isSubscriptionPageAvailable() {
    return this.testAction("SUBSCRIPTION_MANAGEMENT_VIEW")
  }

  get isSubscriptionUpdateAvailable() {
    return this.testAction("SUBSCRIPTION_MANAGEMENT_UPDATE")
  }

  get isUserGroupsAvailable() {
    return this.testAction("VIEW_GROUP")
  }

  get canCreateUserGroups() {
    return this.testAction("CREATE_GROUP")
  }

  get canEditUserGroups() {
    return this.testAction("UPDATE_GROUP")
  }

  get canDeleteUserGroups() {
    return this.testAction("DELETE_GROUP")
  }

  get canManageUserGroupsMembership() {
    return this.testAction("MANAGE_GROUP_USERS")
  }

  get testAction() {
    const { userActions } = this
    if (userActions == null) return () => false
    return (action: string): boolean => {
      return isAllActionsAvailable || !!userActions?.has(action.toLowerCase())
    }
  }

  get testFlag() {
    const { config } = this
    return (param: keyof AppRestrictions): boolean =>
      (isALLFeaturesAvailable || config[param]) ?? false
  }

  get testDataSourceAccess() {
    const dataSourcesBlackList = extractSourceTypeBlackList(
      toJS(this.config),
      dataSourcesRestrictions
    ) as string[]

    return (dataSources: string | string[]) => {
      if (Array.isArray(dataSources))
        return !intersection(dataSources, dataSourcesBlackList).length
      return !dataSourcesBlackList.includes(dataSources)
    }
  }
}

export default RestrictionsStore
