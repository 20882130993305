import clsx from "clsx"
import React from "react"

import Text from "@components/ui/Typography/Text"
import { AnswerItem, TextAnswerType } from "@framework/types/search"
import Avatar from "@components/ui/Avatar/Avatar"

import styles from "./ExpertAnswer.module.sass"

interface PDFViewerProps {
  details: AnswerItem
}

const ExpertAnswer: React.FC<PDFViewerProps> = ({ details }) => {
  const data = details as unknown as TextAnswerType
  return (
    <div className={clsx(styles.root)}>
      <Text variant="h3">Q:{data.source_name}</Text>
      <Text variant="body1">Answer</Text>
      <div className={styles.expertDetails}>
        <Avatar name={data.expert_name} />
        <Text variant="body2">{data.expert_name}</Text>
      </div>
    </div>
  )
}

export default ExpertAnswer
