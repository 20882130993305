import React from "react"
import Markdown from "markdown-to-jsx"
import uniq from "lodash/uniq"

import MarkdownNavLink from "./MarkdownNavLink"

const citationRegex = /(\[\d+\])/g

const citationBracketsRegex = /[\\[\]\s]/g

interface MarkdownWithCitationsProps {
  children?: string
  citationLink?: (value: string) => string
}

const MarkdownWithCitations: React.FC<MarkdownWithCitationsProps> = ({
  children: content = "",
  citationLink,
}) => {
  const matches = citationLink
    ? uniq(content.match(citationRegex)).map((it) =>
        it.replace(citationBracketsRegex, "")
      )
    : []

  const updatedContent = matches.reduce((text, citationIndex) => {
    const citation = `[${citationIndex}]`
    const link = citationLink?.(citationIndex) ?? `/${citationIndex}`
    return text.replaceAll(citation, `${citation}(${link})`)
  }, content)

  return (
    <Markdown
      options={{
        overrides: {
          a: MarkdownNavLink,
        },
      }}
    >
      {updatedContent}
    </Markdown>
  )
}

export default MarkdownWithCitations
