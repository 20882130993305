import React from "react"
import { nanoid } from "nanoid"
import { AutoSizer, Grid, GridCellProps, ScrollParams } from "react-virtualized"
import scrollbarSize from "dom-helpers/scrollbarSize"
import { observer } from "mobx-react-lite"

import useEventListener from "@components/hooks/useEventListener"
import useEvent from "@components/hooks/useEvent"

import Cell from "./Cell"
import MatrixStore from "./state/MatrixStore"
import { SelectionLayer } from "./SelectionLayer"
import { GridStylesCache, MatrixSnapshot } from "./types"
import { MatrixContext } from "./MatrixContext"
import Axis from "./Axis"
import { renderedSectionToRange } from "./utils"
import CellControlHeader from "./CellControlHeader"
import EmptyAxisCell from "./EmptyAxisCell"
import ToolBar from "./ToolBar"

import styles from "./Spreadsheet.module.sass"

type SpreadsheetProps = {
  instance: MatrixStore
  onUpdate?: (snapshot: MatrixSnapshot) => void
}

const Spreadsheet: React.FC<SpreadsheetProps> = observer((props) => {
  const state = props.instance

  const mainRef = React.useRef<Grid | null>(null)
  const xAxisRef = React.useRef<Grid | null>(null)
  const yAxisRef = React.useRef<Grid | null>(null)

  const [rootId] = React.useState(nanoid)

  const keepContainerFocused = () => {
    if (!state.editManager.isEditing) document.getElementById(rootId)?.focus()
  }

  const onBodyRef = (ref: Grid | null) => {
    mainRef.current = ref

    const gridElement = document.getElementById(rootId)?.firstChild
    state.grid.setGridContainer((gridElement as Element) ?? null)

    setTimeout(() => {
      state.grid.setGridStylesCache(
        // eslint-disable-next-line no-underscore-dangle
        (ref as any)?._styleCache as GridStylesCache
      )
    }, 0)
  }

  const [bodySquare, setBodySquare] = React.useState<ScrollParams>()

  const renderBodyCell = ({
    key,
    columnIndex,
    rowIndex,
    style,
  }: GridCellProps) => {
    return (
      <div key={key} style={{ ...style, overflow: "hidden" }} tabIndex={-1}>
        <Cell columnIndex={columnIndex} rowIndex={rowIndex} />
      </div>
    )
  }

  React.useEffect(keepContainerFocused, [state.editManager.isEditing])

  React.useEffect(() => {
    mainRef.current?.recomputeGridSize()
    xAxisRef.current?.recomputeGridSize()
    yAxisRef.current?.recomputeGridSize()
  }, [state.renderTrigger])

  React.useEffect(() => {
    if (state.editManager.isEditing) {
      const cell = state.selectedRange.origin
      mainRef.current?.scrollToCell({ columnIndex: cell.x, rowIndex: cell.y })
    }
  }, [state.editManager.isEditing])

  React.useEffect(() => {
    return () => {
      if (state.editManager.isEditing) state.editManager.cancelCell()
    }
  }, [])

  const handleUpdate = useEvent((snapshot) => props.onUpdate?.(snapshot))

  React.useEffect(() => {
    if (props.onUpdate == null || state.editManager.isEditing) return undefined

    const handler = () => {
      state.serialize().then(handleUpdate)
    }

    const interval = setInterval(handler, 1000)
    return () => clearInterval(interval)
  }, [props.onUpdate != null, state.editManager.isEditing])

  useEventListener({
    type: "mouseup",
    element: document,
    listener: () => {
      state.selectedRange.endRange()

      state.endSpreading()

      state.resizer.end((axis, cellIndex, shift) => {
        const resize =
          axis === "x"
            ? state.grid.resizeCellWidth
            : state.grid.resizeCellHeight

        resize(cellIndex, shift)

        state.render()
      })

      // Add other handlers here
    },
  })

  return (
    <MatrixContext.Provider value={state}>
      <div
        className={styles.root}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          const direction = e.shiftKey ? -1 : 1

          if (!state.editManager.isEditing) {
            e.preventDefault()
            e.stopPropagation()

            const point = state.selectedRange.origin

            if (e.key.length === 1 && !(e.ctrlKey || e.metaKey)) {
              state.editManager.editCell(point, { initialValue: e.key })
              return
            }

            if (e.key === "Enter") state.editManager.editCell(point)

            if (e.key === "Delete" || e.key === "Backspace") {
              state.editManager.cleanUpRange(state.selectedRange.range)
            }

            if (e.key === "ArrowUp") {
              state.moveSelection(0, -1)
            }
            if (e.key === "ArrowDown") {
              state.moveSelection(0, 1)
            }
            if (e.key === "ArrowLeft") {
              state.moveSelection(-1, 0)
            }
            if (e.key === "ArrowRight") {
              state.moveSelection(1, 0)
            }
            if (e.key === "Tab") {
              state.moveSelection(direction, 0)
            }

            if (e.ctrlKey || e.metaKey) {
              if (e.code === "KeyC") state.editManager.copy()

              if (e.code === "KeyV") state.editManager.paste()

              if (e.code === "KeyX") state.editManager.cut()
            }

            return
          }

          if (e.key === "Escape") state.editManager.cancelCell()

          if (e.key === "Enter" && !e.shiftKey) {
            state.editManager.submitCell()

            state.moveSelection(0, 1)

            e.stopPropagation()
            e.preventDefault()
          }

          if (e.key === "Tab") {
            state.editManager.submitCell()

            state.moveSelection(direction, 0)

            e.stopPropagation()
            e.preventDefault()
          }
        }}
      >
        <ToolBar />

        <CellControlHeader />

        <div className={styles.body}>
          <EmptyAxisCell />

          <div className={styles.hAxisContainer}>
            <div style={{ flex: "1" }}>
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <Axis
                      axis="x"
                      ref={xAxisRef}
                      height={height}
                      width={width}
                      scrollLeft={bodySquare?.scrollLeft}
                    />
                  )
                }}
              </AutoSizer>
            </div>
            <EmptyAxisCell width={scrollbarSize()} />
          </div>

          <div className={styles.vAxisContainer}>
            <div style={{ flex: "1" }}>
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <Axis
                      axis="y"
                      ref={yAxisRef}
                      height={height}
                      width={width}
                      scrollTop={bodySquare?.scrollTop}
                    />
                  )
                }}
              </AutoSizer>
            </div>
            <EmptyAxisCell height={scrollbarSize()} />
          </div>

          <div className={styles.gridContainer}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Grid
                    id={rootId}
                    ref={onBodyRef}
                    className={styles.grid}
                    cellRenderer={renderBodyCell}
                    columnCount={state.grid.totalColumns}
                    rowCount={state.grid.totalRows}
                    width={width}
                    height={height}
                    overscanRowCount={state.grid.overscanCount}
                    columnWidth={({ index }) => state.grid.getCellWidth(index)}
                    rowHeight={({ index }) => state.grid.getCellHeight(index)}
                    overscanColumnCount={state.grid.overscanCount}
                    onScroll={setBodySquare}
                    onSectionRendered={(e) => {
                      state.grid.setVisibleRect(renderedSectionToRange(e))
                    }}
                    tabIndex={-1}
                  />
                )
              }}
            </AutoSizer>
          </div>
        </div>

        <SelectionLayer />
      </div>
    </MatrixContext.Provider>
  )
})

export default Spreadsheet
