import { makeAutoObservable } from "mobx"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import { Filename } from "@framework/types/company"

import SimpleCollectionStore from "./simple-entity-collection.store"
import MatrixSnapshotStore from "./matrix-snapshot.store"

export default class UnifiedMatrixStore {
  /**
   * @key solution ID
   */
  instances: Map<string, MatrixStore>

  filesStore: SimpleCollectionStore<Filename>

  snapshotStore: MatrixSnapshotStore

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    this.instances = new Map()
    this.filesStore = new SimpleCollectionStore()
    this.snapshotStore = new MatrixSnapshotStore()

    makeAutoObservable(this)
  }

  getMatrixInstance = (solutionID: string): MatrixStore | null => {
    return this.instances.get(solutionID) ?? null
  }
}
