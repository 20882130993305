import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./ImagePreview.module.sass"

interface PDFViewerProps {
  src: string
  checked?: boolean
  onClick?: () => void
}

const ImagePreview: React.FC<PDFViewerProps> = ({
  src,
  checked = false,
  onClick,
}) => (
  <div
    tabIndex={0}
    role="button"
    onKeyPress={() => {}}
    onClick={onClick}
    className={clsx(styles.root, { [styles.active]: checked })}
  >
    {src && <img src={src} alt={src} />}
    {!src && (
      <div className={styles.noImage}>
        <Icon name="landscape" />
        <Text variant="h4">Image Unavailable</Text>
      </div>
    )}
  </div>
)

export default ImagePreview
