import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"

import { AvatarData } from "@framework/types/avatar"
import { useController, useStore } from "@store/index"
import FileCard from "@components/ui/FileCard"
import QueryAttachment from "@store/search/query-attachment"
import SearchInput from "@pages/launch-solutions/SolutionTabsContainer/SearchInput"

import styles from "./SearchWidget.module.sass"

interface SearchWidgetProps {
  disabled?: boolean
  avatar: AvatarData
  onSearch?: () => void
}

const validationSchema = yup.object({
  query: yup.string().trim().min(3).default(""),
})

const SearchWidget: React.FC<SearchWidgetProps> = observer(
  ({ avatar, disabled, onSearch }) => {
    const {
      documentChatSolutionStore: { queryAttachments, searchFlowStore },
      solutionsStore: { solution, appliedFilters },
    } = useStore()

    const { documentChatSolutionController: controller, solutionsController } =
      useController()

    const handleSearch = async ({ query }: { query: string }) => {
      if (solution?.id)
        controller.search(query, avatar, solution?.id, appliedFilters)

      formik.resetForm({})
      onSearch?.()
    }

    const formik = useFormik({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      validateOnBlur: true,
      onSubmit: handleSearch,
    })
    useEffect(() => {
      if (solutionsController) {
        solutionsController.clearAllFilters()
      }
    }, [solutionsController])

    const handleAttachmentRemove = (attachment: QueryAttachment) => {
      controller.deleteAttachment(attachment)
      searchFlowStore.reset()
    }

    return (
      <div className={styles.root} aria-disabled={disabled}>
        {queryAttachments.attachments.map((item) => (
          <FileCard
            key={item.id}
            name={item.file.name}
            size="small"
            onRemove={() => handleAttachmentRemove(item)}
            disabled={disabled}
          />
        ))}

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <section className={styles.searchContainer}>
              <SearchInput disabled={disabled} />
            </section>
          </form>
        </FormikProvider>
      </div>
    )
  }
)

export default SearchWidget
