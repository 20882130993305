import React from "react"
import { observer } from "mobx-react-lite"

import AnswerOriginalView, {
  ImageDetails,
} from "./AnswerOriginalView/AnswerOriginalView"

import styles from "./ImageResult.module.sass"

export interface ImgProps {
  imageUrls: ImageDetails[]
  onSelect: (imageDetails: ImageDetails) => void
}

export const ImageData: React.FC<ImgProps> = observer(
  ({ imageUrls, onSelect }) => {
    return (
      <div className={styles.root}>
        <AnswerOriginalView images={imageUrls} onSelect={onSelect} />
      </div>
    )
  }
)

export default ImageData
