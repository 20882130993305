import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import List from "@components/ui/List/List"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import IntroLayout from "@components/layout/IntroLayout/IntroLayout"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import LoginSSOForm from "@pages/auth/LoginSSOForm"
import LoginMSSSOForm from "@pages/auth/LoginMSSSOForm"

import ExternalSignForm from "./ExternalSignUpForm"
import SignInForm from "./components/SignInForm/SignInForm"

import styles from "./SignIn.module.sass"

export interface SignInPageProps {}

const SignInUsingSSOForm: React.FC<{ primary?: boolean }> = observer(
  ({ primary }) => {
    const theme = useAppConfig()
    const { restrictionsStore: access } = useStore()

    const isSSOTypeSAMLAvailable = access.isConfigDrivenAuth
      ? theme.authorization.login.SSO.variant === "SAML"
      : access.isSSOTypeSAMLAvailable

    const isSSOTypeOIDCAvailable =
      access.isConfigDrivenAuth &&
      theme.authorization.login.SSO.variant === "OIDC"

    if (isSSOTypeSAMLAvailable) {
      return <LoginSSOForm variant="SAML" primary={primary} />
    }

    if (isSSOTypeOIDCAvailable) {
      return <LoginSSOForm variant="OIDC" primary={primary} />
    }

    return <LoginMSSSOForm primary={primary} />
  }
)

const SignInPage: React.FC<SignInPageProps> = observer(() => {
  const theme = useAppConfig()

  const navigate = useNavigate()
  const { restrictionsStore: access } = useStore()

  const isSSOAvailable = access.isConfigDrivenAuth
    ? !theme.authorization.login.SSO.disabled
    : access.isSSOAvailable

  const renderActionNodes = () => {
    const actionNodes: React.ReactNode[] = []

    let wasPrimaryRender = false

    const addAction = (callback: (isPrimary: boolean) => React.ReactNode) => {
      actionNodes.push(callback(!wasPrimaryRender))
      wasPrimaryRender = true
    }

    // maintained auth actions

    if (!theme.authorization.login.classic.disabled)
      addAction((isPrimary) => <SignInForm primary={isPrimary} />)

    if (isSSOAvailable)
      addAction((isPrimary) => <SignInUsingSSOForm primary={isPrimary} />)

    if (!theme.authorization.signUp.external.disabled)
      addAction((isPrimary) => (
        <>
          <Text color="text50Color" align="center">
            Don&lsquo;t have a Personal Account?
          </Text>

          <ExternalSignForm primary={isPrimary} />
        </>
      ))

    //

    return actionNodes
  }

  return (
    <IntroLayout>
      <div className={styles.root}>
        {!theme.authorization.signUp.classic.disabled && (
          <div className={styles.header}>
            Don&lsquo;t have an account?
            <Button
              variant="outlined"
              size="medium"
              onClick={() => navigate(mainRoutes.signUp())}
            >
              Sign Up
            </Button>
          </div>
        )}

        <List justify="center" gutter="24" className={styles.body}>
          <Text variant="h1" align="center">
            Log In
          </Text>

          {renderActionNodes()}
        </List>

        {access.isAppAgreementsStampAvailable && (
          <div className={styles.footer}>
            By signing in or creating an account, you agree with our&nbsp;
            <Link to="/terms">Terms & Conditions</Link> and&nbsp;
            <Link to="/privacy">Privacy Statement</Link>
          </div>
        )}
      </div>
    </IntroLayout>
  )
})

export default SignInPage
